<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    DropZone
  },
  setup() {
    let image = ref("");
    let dropzoneFile = ref("");
    let url = ref(null);
    const drop = e => {
      dropzoneFile.value = e.dataTransfer.files[0];
      url = URL.createObjectURL(e.dataTransfer.files[0]);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      image.value = e.dataTransfer.files[0];
      document.querySelector("#p-img").src = url;
      img.id = "prev-img-el";
      document.querySelector(".img-prev").appendChild(img);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      url = URL.createObjectURL(
        document.querySelector(".dropzoneFile").files[0]
      );
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector("#p-img").src = url;
      image.value = document.querySelector(".dropzoneFile").files[0];
      document.querySelector(".img-prev").appendChild(img);
      console.log(dropzoneFile.value);
      console.log(url);
    };
    return { url, dropzoneFile, drop, selectedFile, image };
  },
  data() {
    return {
      offer_details: [],
      order_deatiles: [],
      product_ads: [],
      selectedProduct: {},
      Products: [],
      productSizes: [],
      page: 1,
      limit: 10,
      tot_pages: 0,
      searchProducts: null,
      ProductsSearchMode: false,
      searchModel: "",
      state: state,
      categories: [],
      branches: [],
      loading: false
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000
      });
    },
    getCateogriesAndBranches() {
      this.http.post("products/categories-branches", {
        id:this.$route.params
      }).then(res => {
        this.categories = res.data.categories;
        this.branches = res.data.branches;
      });
    },
    addProducts() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "products.name"
          },
          {
            model: "image",
            type: "file",
            label: "products.image"
          },
          {
            model: "price",
            type: "text",
            label: "products.price"
          },
          {
            model: "qty",
            type: "number",
            label: "products.qty"
          },
          {
            model: "size_id",
            type: "select",
            label: "products.size_id",
            options: (() => {
              return this.productSizes.map(data => {
                return { value: data.id, label: data.size };
              });
            })()
          },
          {
            model: "max_ordered_qty",
            type: "number",
            label: "products.max_ordered_qty"
          },
          {
            model: "calories",
            type: "text",
            label: "products.calories"
          },
          {
            model: "descr",
            type: "text",
            label: "products.descr"
          },
          {
            model: "category_id",
            type: "select",
            label: "products.category_id",
            options: (() => {
              return this.categories.map(data => {
                return { value: data.id, label: data.name };
              });
            })()
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.do("products", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    confirmEdit() {
      if (this.image) {
        this.selectedProduct.image = this.image;
        this.http
          .do("products/update", {
            ...this.selectedProduct
          })
          .then(res => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم تعديل المنتج بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Product hass been edited successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء تعديل المنتج",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }

            this.get(this.page);
          });
        return;
      }
      this.http
        .put("products", this.selectedProduct.id, this.selectedProduct)
        .then(res => {
          if (res.status) {
            if (this.$i18n.locale == "ar") {
              this.responseAlert("تم تعديل المنتج بنجاح", " تم ", "success");
            } else {
              this.responseAlert(
                "Product hass been edited successfully",
                "done",
                "success"
              );
            }
          } else {
            if (this.$i18n.locale == "ar") {
              this.responseAlert(
                "حدث خطأ اثناء تعديل المنتج",
                "خطأ",
                "warning"
              );
            } else {
              this.responseAlert("something went wrong", "error", "warning");
            }
          }
          this.get(this.page);
        });
    },
    bindDescr(app) {
      this.selectedProduct = app;
    },
    editProducts(app) {
      this.selectedProduct = app;
      setTimeout(() => {
        let container = document.querySelector("#text-container");
        if (container.hasChildNodes()) {
          window.ClassicEditor.remove(document.querySelector("#editor"));
          document.querySelector("#editor").remove();
        }
        let ta = document.createElement("textarea");
        ta.id = "editor";
        ta.classList.add("form-control");
        container.appendChild(ta);
        window.ClassicEditor.create(document.querySelector("#editor"))
          .then(editor => {
            editor.setData(app.descr);
          })
          .catch(error => {
            console.error(error);
          });
      }, 300);
      // var data = {
      //   title: "popups.edit",
      //   inputs: [
      //     {
      //       model: "name",
      //       type: "text",
      //       label: "products.name",
      //       value: app.name,
      //     },
      //     {
      //       model: "image",
      //       type: "text",
      //       label: "products.image",
      //       value: app.image,
      //     },
      //     {
      //       model: "price",
      //       type: "text",
      //       label: "products.price",
      //       value: app.price,
      //     },
      //     {
      //       model: "qty",
      //       type: "text",
      //       label: "products.qty",
      //       value: app.qty,
      //     },
      //     {
      //       model: "size_id",
      //       type: "text",
      //       label: "products.size_id",
      //       value: app.size_id,
      //     },
      //     {
      //       model: "max_ordered_qty",
      //       type: "text",
      //       label: "products.max_ordered_qty",
      //       value: app.max_ordered_qty,
      //     },
      //     {
      //       model: "calories",
      //       type: "text",
      //       label: "products.calories",
      //       value: app.calories,
      //     },
      //     {
      //       model: "descr",
      //       type: "text",
      //       label: "products.descr",
      //       value: app.descr,
      //     },
      //     {
      //       model: "category_id",
      //       type: "text",
      //       label: "products.category_id",
      //       value: app.category_id,
      //     },
      //   ],
      //   buttons: [
      //     {
      //       text: "popups.edit",
      //       closer: true,
      //       color: "primary",
      //       handler: (obj) => {
      //         this.http.put("products", app.id, obj).then(() => {
      //           this.get(this.page);
      //         });
      //       },
      //     },
      //     {
      //       text: "popups.cancel",
      //       closer: true,
      //       color: "danger",
      //       handler: () => {
      //         console.log("Like Clicked");
      //       },
      //     },
      //   ],
      // };
      // this.popup.modal(data);
    },
    search() {
      this.loading = true;
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        this.loading = false;
        return;
      }
      this.ProductsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("products/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name"
        })
        .then(res => {
          if (res.status) {
            this.Products = res.data;
            this.loading = false;
          }
          console.log("######## files", this.Products);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.ProductsSearchMode = false;
      this.get(this.page);
    },

    getProducts() {
      this.http.get("products").then(res => {
        this.Products = res.data;
      });
    },
    deleteProducts(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          this.loading = true;
          this.http.delete("products", app.id).then(res => {
            if (res.status) {
              this.loadin = false;
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم حذف المنتج بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Product hass been deleted successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء حذف المنتج",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      this.loading = true;
      console.log(page);
      this.http
        .post("products/vendors/paginate", {
          limit: this.limit,
          page: page,
          id: this.$route.params.id
        })
        .then(res => {
          this.loading = false;
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Products = res.data;
        });
    }
  },
  created() {
    this.get(1);
    // this.getCateogriesAndBranches();
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.products.sub.products')"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="row">
                <div class="col-sm-4">
                  <div class="search-box me-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input
                        @keyup="search()"
                        type="text"
                        class="form-control"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                        :placeholder="$t('popups.search')"
                        v-model="searchModel"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8" style="display: flex; justify-content: flex-end">
                  <router-link to="/add-product/1">
                    <button class="btn btn-success btn-rounded mb-2 me-2">
                      <i class="mdi mdi-plus me-1"></i>
                      {{ $t("products.new.title") }}
                    </button>
                  </router-link>
                </div>
              </div>

              <div>
                <div class="table-responsive">
                  <table
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="table table-centered table-nowrap table-striped table-hover align-middle"
                  >
                    <thead>
                      <tr class="text-light" style="background-color: #2a3042 !important">
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("products.id") }}</th>
                        <th scope="col">{{ $t("products.name") }}</th>
                        <th scope="col">{{ $t("products.category_id") }}</th>
                        <th scope="col">{{ $t("products.type") }}</th>
                        <th scope="col">{{ $t("products.image") }}</th>
                        <th scope="col">{{ $t("products.price") }}</th>
                        <th scope="col">{{ $t("products.qty") }}</th>
                        <th scope="col">{{ $t("products.critical_qty") }}</th>
                        <th scope="col">{{ $t("products.max_ordered_qty") }}</th>
                        <th scope="col">{{ $t("products.descr") }}</th>
                        <th scope="col">{{ $t("products.operations") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(app, index) in Products" :key="app">
                        <td>{{ index + 1 }}</td>
                        <td>{{ app.id }}</td>
                        <td>{{ app?.name }}</td>
                        <td>
                          {{
                          $i18n.locale == "ar"
                          ? app?.category_ar
                          : app?.category
                          }}
                        </td>
                        <td>
                          {{
                          app?.has_options
                          ? $i18n.locale == "ar"
                          ? "متغير"
                          : "variable"
                          : $i18n.locale == "ar"
                          ? "عادي"
                          : "normal"
                          }}
                        </td>
                        <td>
                          <img
                            id="p-img"
                            :src="
                              $store.state?.auth?.mediaUrl +
                              app?.image?.split('public')[1]
                            "
                            style="width: 64px; height: 64px"
                            alt
                          />
                        </td>
                        <td>{{ app?.has_options ? "-" : app?.price }}</td>
                        <td>{{ app?.has_options ? "-" : app?.qty }}</td>
                        <td>{{ app?.has_options ? "-" : app?.critical_qty }}</td>
                        <td>{{ app?.has_options ? "-" : app?.max_ordered_qty }}</td>
                        <td>
                          <a
                            @click="bindDescr(app)"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#showModal"
                          >{{ $t("products.show") }}</a>
                        </td>
                        <td>
                          <router-link
                            style="margin-inline-end: 8px"
                            :to="'/products/product/' + app?.id"
                          >
                            <button
                              class="btn btn-info"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="app?.name + ' ' + $t('products.profile')"
                            >
                              <i class="bx bxs-detail"></i>
                            </button>
                          </router-link>
                          <button
                            style="margin-inline-end: 8px"
                            class="btn btn-success"
                            @click="editProducts(app)"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                          >{{ $t("popups.edit") }}</button>
                          <button
                            class="btn btn-danger"
                            @click="deleteProducts(app)"
                          >{{ $t("popups.delete") }}</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end  table -->
                <!-- Button trigger modal -->

                <div>
                  <!--   Apps  paginate     -->
                  <ul class="pagination pagination-rounded justify-content-end mb-2">
                    <li class="page-item" :class="{ disabled: page == 1 }">
                      <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="get(page - 1)"
                        aria-label="Previous"
                      >
                        <i class="mdi mdi-chevron-left"></i>
                      </a>
                    </li>
                    <li
                      class="page-item"
                      :class="{ active: p == page }"
                      v-for="p in tot_pages"
                      :key="p"
                    >
                      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{ p }}</a>
                    </li>

                    <li class="page-item" :class="{ disabled: page == tot_pages }">
                      <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="get(page + 1)"
                        aria-label="Next"
                      >
                        <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <Loader v-if="loading" :loading="loading" />

              <!--end-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start edit product modal -->

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
              >{{ $t("popups.edit") }} {{ selectedProduct?.name }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="p-2">
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("products.name") }}</label>
                  <input type="text" class="form-control" name v-model="selectedProduct.name" id />
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <label class="control-label">
                    {{
                    $t("products.new.category")
                    }}
                  </label>
                  <select v-model="selectedProduct.category_id" class="form-control" name id>
                    <option
                      :selected="category.id == selectedProduct.category_id"
                      :key="category.id"
                      :value="category.id"
                      v-for="category in categories"
                    >
                      {{
                      $i18n.locale == "ar" ? category.name_ar : category.name
                      }}
                    </option>
                  </select>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("products.image") }}</label>
                  <!-- <input
                  ref="myFile"
                  @change="selectedFile"
                  id="dropzone-file"
                  type="file"
                  name="photos"
                  class="form-control"
                  />-->
                  <DropZone @drop.prevent="drop" @change="selectedFile()" />
                </div>
                <div class="my-2 img-prev">
                  <img
                    style="width: 150px; height: 150px"
                    :src="
                      $store.state?.auth?.mediaUrl +
                      selectedProduct?.image?.split('public')[1]
                    "
                    id="prev-img-el"
                    alt
                  />
                </div>
                <div class="row d-flex align-items-center mb-2" v-if="!selectedProduct.has_options">
                  <label>{{ $t("products.price") }}</label>
                  <input type="number" class="form-control" name v-model="selectedProduct.price" id />
                </div>
                <div class="row d-flex align-items-center mb-2" v-if="!selectedProduct.has_options">
                  <label>{{ $t("products.cost_price") }}</label>
                  <input
                    type="number"
                    class="form-control"
                    name
                    v-model="selectedProduct.cost_price"
                    id
                  />
                </div>
                <div class="row d-flex align-items-center mb-2" v-if="!selectedProduct.has_options">
                  <label>{{ $t("products.qty") }}</label>
                  <input type="number" class="form-control" name v-model="selectedProduct.qty" id />
                </div>
                <div class="row d-flex align-items-center mb-2" v-if="!selectedProduct.has_options">
                  <label>{{ $t("products.critical_qty") }}</label>
                  <input
                    type="number"
                    class="form-control"
                    name
                    v-model="selectedProduct.critical_qty"
                    id
                  />
                </div>
                <div class="row d-flex align-items-center mb-2" v-if="!selectedProduct.has_options">
                  <label>{{ $t("products.max_ordered_qty") }}</label>
                  <input
                    type="number"
                    class="form-control"
                    name
                    v-model="selectedProduct.max_ordered_qty"
                    id
                  />
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("products.descr") }}</label>
                  <div id="text-container"></div>
                </div>
              </div>
            </div>

            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button
                data-bs-dismiss="modal"
                @click="confirmEdit()"
                class="btn btn-success"
              >{{ $t("popups.edit") }}</button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-secondary"
              >{{ $t("popups.cancel") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end edit product modal -->

    <!-- start edit product modal -->

    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t("products.descr") }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="p-2">
                <div class="row d-flex align-items-center mb-2">
                  <div v-html="selectedProduct.descr"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end edit show modal -->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: absolute;
  right: 0px;
}
</style>
